<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col lg="4" cols="12">
              <b-card>
                <b-row>
                  <b-col cols="6">
                    <h5 class="text-capitalize">Title</h5>
                    <template v-if="lead?.title">
                      <div>
                        <b-card-text> {{ lead?.title }}</b-card-text>
                      </div>
                    </template></b-col
                  >
                  <b-col cols="6">
                    <h5 class="text-capitalize">Key Contact Person</h5>
                    <template v-if="lead?.key_contact_person">
                      <div>
                        <b-card-text>
                          {{ lead?.key_contact_person }}</b-card-text
                        >
                      </div>
                    </template>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <div class="mt-2">
                      <h5 class="text-capitalize">Source</h5>
                      <template v-if="lead?.lead_source_id">
                        <b-card-text>
                          {{ lead?.leadSource?.data?.name }}
                        </b-card-text>
                      </template>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="mt-2">
                      <h5 class="text-capitalize">Category</h5>
                      <template v-if="lead?.lead_category_id">
                        <b-card-text>
                          {{ lead?.leadCategory?.data?.name }}
                        </b-card-text>
                      </template>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <div class="mt-2">
                      <h5 class="text-capitalize">Department</h5>
                      <template v-if="lead?.department_id">
                        <b-card-text>
                          {{ lead?.department?.data?.name }}
                        </b-card-text>
                      </template>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="mt-2">
                      <h5 class="text-capitalize">Assigned To</h5>
                      <template v-if="lead?.team">
                        <b-card-text>
                          <span v-for="(item, index) in lead.team.data" :key="index">

                            <UserCard :user="item" />

                          </span>
                        </b-card-text>
                      </template>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col lg="4" cols="12">
              <b-card>
                <b-row>
                  <b-col cols="6">
                    <h5 class="text-capitalize">Company Name</h5>
                    <template v-if="lead?.company_name">
                      <div>
                        <b-card-text> {{ lead?.company_name }}</b-card-text>
                      </div>
                    </template>
                  </b-col>
                  <b-col cols="6">
                    <div>
                      <h5 class="text-capitalize">Email</h5>
                      <template v-if="lead?.email">
                        <b-link :href="'mailto:' + lead?.email">
                          {{ lead?.email }}
                        </b-link>
                        <!-- <b-card-text> {{ lead?.email }}</b-card-text> -->
                      </template>
                      <template v-else-if="!lead?.email">
                        <span>N/A</span></template
                      >
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <div class="mt-2">
                      <h5 class="text-capitalize">Mobile</h5>
                      <template v-if="lead?.mobile">
                        <b-link :href="'tel:' + lead?.mobile">
                          {{ lead?.mobile }}
                        </b-link>
                      </template>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="mt-2">
                      <h5 class="text-capitalize">Tel</h5>
                      <template v-if="lead?.phone">
                        <b-link :href="'tel:' + lead?.phone">
                          {{ lead?.phone }}
                        </b-link>
                      </template>
                      <template v-else>
                        <span>N/A</span>
                      </template>
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <div class="mt-2">
                      <h5 class="text-capitalize">Address</h5>
                      <template v-if="lead?.address">
                        <b-card-text>
                          {{ lead?.address }} <br />
                          State: {{ lead?.state ? lead?.state : "N/A" }} , City:
                          {{ lead?.city ? lead?.city : "N/A" }}, Street:
                          {{ lead?.street ? lead?.street : "N/A" }}, Zip Code:
                          {{ lead?.zip_code ? lead?.zip_code : "N/A" }}
                        </b-card-text>
                      </template>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="mt-2">
                      <h5 class="text-capitalize">Country</h5>
                      <template v-if="lead?.country_id">
                        <b-card-text>
                          {{ lead?.leadCountry?.data.name }}</b-card-text
                        >
                      </template>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col lg="4" cols="12">
              <b-card>
                <b-row>
                  <b-col cols="6">
                    <div>
                      <h5 class="text-capitalize">Web Site Link</h5>
                      <template v-if="lead?.website_link">
                        <b-link :href="lead?.website_link" target="_blank">
                          {{ lead?.website_link }}</b-link
                        >
                      </template>
                      <template v-else-if="!lead?.website_link"> N/A </template>
                    </div>
                  </b-col>
                  <b-col cols="4">
                    <h5 class="text-capitalize">Status</h5>
                    <template v-if="lead?.lead_status_id">
                      <b-card-text>
                        <b-badge
                          pill
                          :style="{
                            background: lead?.leadStatus?.data?.color_code,
                          }"
                          >{{ lead?.leadStatus?.data?.name }}</b-badge
                        >
                        <template
                          v-if="
                            lead?.leadStatus?.data?.type == 2 &&
                            lead?.estimation_sent == false
                          "
                        >
                          <b-badge class="badge-glow mt-1" variant="danger"
                            >Estimation Not Sent</b-badge
                          >
                        </template>
                        <template
                          v-else-if="
                            lead?.leadStatus?.data?.type == 2 &&
                            lead?.estimation_sent
                          "
                        >
                          <b-badge class="badge-glow mt-1" variant="success"
                            >Estimation Sent</b-badge
                          >
                        </template>
                      </b-card-text>
                    </template>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <div class="mt-2">
                      <h5 class="text-capitalize">Last Contact Date & Time</h5>
                      <template v-if="lead?.last_contact_date">
                        <b-card-text>
                          {{
                            lead?.last_contact_date
                              | moment("ddd, MMM Do YYYY, h:mm a")
                          }}
                        </b-card-text>
                      </template>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="mt-2">
                      <h5 class="text-capitalize">Next Contact Date & Time</h5>
                      <template v-if="lead?.next_contact_date">
                        <b-card-text>
                          {{
                            lead?.next_contact_date
                              | moment("ddd, MMM Do YYYY, h:mm a")
                          }}
                        </b-card-text>
                      </template>
                      <template v-else-if="!lead?.next_contact_date"
                        >N/A</template
                      >
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <div class="mt-2">
                      <h5 class="text-capitalize">Note</h5>
                      <template v-if="lead?.note">
                        <b-card-text> {{ lead?.note }}</b-card-text>
                      </template>
                      <template v-else-if="!lead?.note">
                        <b-card-text> N/A</b-card-text>
                      </template>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12" cols="12">
        <b-card>
          <div>
            <!-- search input -->
            <template v-if="lead?.leadStatus?.data?.type == 2">
              <b-alert variant="success" show>
                <h4 class="alert-heading">
                  This lead has been converted successfully & locked!
                  <template v-if="lead?.estimation_sent == false">
                    <b-button
                      class="flex-shrink-0 ml-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      v-on:click="showEstimationModal"
                    >
                      Financial Estimation
                    </b-button></template
                  >
                </h4>
              </b-alert>
            </template>
            <template v-else-if="lead?.leadStatus?.data?.type == 3">
              <b-alert variant="danger" show>
                <h4 class="alert-heading">This lead has been lost & locked!</h4>
              </b-alert></template
            >
            <div
              class="custom-search d-flex align-items-center justify-content-end"
            >
              <div
                class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
              >
                <template
                  v-if="$permissionAbility(LEAD_ACTIVITY_CREATE, permissions)"
                >
                  <template v-if="lead?.leadStatus?.data?.type == 1">
                    <b-button
                      class="flex-shrink-0"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      v-on:click="showModal"
                    >
                      Create Activity Log
                    </b-button>
                  </template>
                </template>
              </div>
            </div>

            <vue-good-table
              mode="remote"
              styleClass="vgt-table lead-details-table striped condensed"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :rows="rows"
              :sort-options="{
                enabled: true,
                multipleColumns: true,
                initialSortBy: [{ field: 'last_contact_date', type: 'desc' }],
              }"
              :columns="columns"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- File -->

                <template v-if="props?.column?.field === 'format_file'">
                  <template v-if="props?.row?.file">
                    <b-link :href="props?.row?.file" target="_blank">
                      Download File
                    </b-link>
                  </template>
                  <template v-else>
                    <span>No Files To Download</span>
                  </template>
                </template>

                <template v-if="props?.column?.field === 'format_remarks'">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    v-on:click="showDescriptionModal(props.row?.remarks)"
                    class="btn-sm"
                  >
                    <feather-icon icon="InfoIcon" />
                  </b-button>
                </template>

                <template v-if="props?.column?.field === 'format_lead_status'">
                  <b-badge
                    pill
                    :style="{
                      background: props?.row?.leadStatus?.data?.color_code,
                    }"
                  >
                    {{ props?.row?.leadStatus?.data?.name }}
                  </b-badge>
                </template>
                <template v-if="props?.column?.field === 'format_created_by'">
                  {{ props?.row?.created_by?.name }}
                </template>
                <span v-if="props?.column?.field === 'action'">
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>

                      <template
                        v-if="
                          $permissionAbility(LEAD_ACTIVITY_EDIT, permissions)
                        "
                      >
                        <b-dropdown-item v-on:click="onShow(props?.row)">
                          <feather-icon icon="Edit2Icon" class="mr-50" />
                          <span>Edit</span>
                        </b-dropdown-item>
                      </template>

                      <template
                        v-if="
                          $permissionAbility(LEAD_ACTIVITY_DELETE, permissions)
                        "
                      >
                        <b-dropdown-item v-on:click="onDelete(props?.row?.id)">
                          <feather-icon icon="TrashIcon" class="mr-50" />
                          <span>Delete</span>
                        </b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props?.column?.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '25', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props?.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
          <b-modal
            id="description-modal"
            centered
            title="Remarks"
            hide-footer
            @hidden="hiddenDescriptionModal"
            no-close-on-backdrop
          >
            <b-card-text>
              {{ modalDescription }}
            </b-card-text>
          </b-modal>
          <!-- Activity Log Modal -->
          <b-modal
            id="modal-activity-log-show-form"
            centered
            :title="
              modelType == 'editModel'
                ? 'Edit Activity Log'
                : 'Create Activity Log '
            "
            hide-footer
            @hidden="hiddenModal"
            no-close-on-backdrop
          >
            <validation-observer ref="leadActvityLogValidation">
              <b-form v-on:submit.prevent="validationForm">
                <b-row>
                  <template
                    v-if="$permissionAbility(LEAD_ACTIVITY_EDIT, permissions)"
                  >
                    <b-col md="10" lg="10" xs="12">
                      <b-form-group
                        label="Lead Status "
                        label-for="leadStatusId"
                        class="required-label"
                      >
                        <ValidationProvider
                          name="lead status"
                          v-slot="{ errors }"
                          vid="lead_status_id"
                          rules="required"
                        >
                          <v-select
                            id="division-id"
                            placeholder="Assign a Status"
                            v-model="leadStatusId"
                            :options="statusIdOption"
                            v-on:input="checkForFinalStatus"
                            :reduce="(country) => country.id"
                            label="name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col md="2" lg="2" xs="12">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-secondary"
                        class="btn-icon rounded-circle mt-2"
                        v-b-tooltip.hover.top="'Reset Status List!'"
                        v-on:click="loadAllStatus"
                      >
                        <feather-icon icon="RefreshCcwIcon" />
                      </b-button>
                    </b-col>
                  </template>
                  <template
                    v-if="!$permissionAbility(LEAD_ACTIVITY_EDIT, permissions)"
                  >
                    <b-col md="12" lg="12" xs="12">
                      <b-form-group
                        label="Lead Status *"
                        label-for="leadStatusId"
                      >
                        <ValidationProvider
                          name="lead status"
                          v-slot="{ errors }"
                          vid="lead_status_id"
                          rules="required"
                        >
                          <v-select
                            id="division-id"
                            placeholder="Assign a Status"
                            v-model="leadStatusId"
                            :options="statusIdOption"
                            v-on:input="checkForFinalStatus"
                            :reduce="(item) => item.id"
                            label="name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col></template
                  >
                </b-row>

                <!-- select contact medium -->
                <b-form-group
                  label="Contact Medium "
                  label-for="contact-through"
                  class="required-label"
                >
                  <ValidationProvider
                    name="contact medium"
                    v-slot="{ errors }"
                    vid="contact_through"
                    rules="required"
                  >
                    <v-select
                      id="contact-through"
                      v-model="contactMedium"
                      :options="selectContactMediumOption"
                      :reduce="(medium) => medium.value"
                      placeholder="Choose A Contact Medium"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <!-- remarks -->
                <b-form-group
                  label="Remarks "
                  label-for="remarks"
                  class="required-label"
                >
                  <ValidationProvider
                    name="remarks"
                    v-slot="{ errors }"
                    vid="remarks"
                    rules="required"
                  >
                    <b-form-textarea
                      id="remarks"
                      type="text"
                      v-model="remarks"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Meeting Remarks"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <!-- file -->
                <template
                  v-if="
                    selectedLeadStatus?.type == 2
                  "
                >
                  <b-form-group
                    label="Project Proposal (Ext allowed : jpg,jpeg,png,pdf,csv,xlsx,txt,docx) "
                    label-for="file"
                    class="required-label"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="attachment"
                      rules="required|size:2048|ext:jpg,jpeg,png,pdf,csv,xlsx,txt,docx"
                      vid="lead_activity_log_file"
                    >
                      <b-form-file
                        id="file"
                        v-model="file"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here... "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
                <template v-else>
                  <b-form-group
                    label="Attach File (Ext allowed : jpg,jpeg,png,pdf,csv,xlsx,txt,docx)"
                    label-for="file"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="attachment"
                      rules="size:2048|ext:jpg,jpeg,png,pdf,csv,xlsx,txt,docx"
                      vid="lead_activity_log_file"
                    >
                      <b-form-file
                        id="file"
                        v-model="file"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here... "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>

                <template
                  v-if="
                    selectedLeadStatus?.type == 2 ||
                    selectedLeadStatus?.type == 3
                  "
                >
                  <b-form-group
                    label="Next Contact Date"
                    label-for="next_contact_date"
                  >
                    <ValidationProvider
                      name="next contact date"
                      v-slot="{ errors }"
                      vid="next_contact_date"
                    >
                      <flat-pickr
                        id="next-contact-date"
                        v-model="nextContactDate"
                        class="form-control"
                        :state="errors.length > 0 ? false : null"
                        locale="en-US"
                        place
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i:S',
                        }"
                        placeholder="Select Date & Time"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </template>
                <template v-else>
                  <b-form-group
                    label="Next Contact Date"
                    label-for="next_contact_date"
                    class="required-label"
                  >
                    <ValidationProvider
                      name="next contact date"
                      v-slot="{ errors }"
                      vid="next_contact_date"
                      rules="required"
                    >
                      <flat-pickr
                        id="next-contact-date"
                        v-model="nextContactDate"
                        class="form-control"
                        :state="errors.length > 0 ? false : null"
                        locale="en-US"
                        place
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i:S',
                        }"
                        placeholder="Select Date & Time"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </template>

                <!-- loading button -->
                <template v-if="isLeadActivityFormSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-form>
            </validation-observer>
          </b-modal>

          <!-- Financial Estimation Modal -->

          <FinancialEstimationForm
            :selectedLeadInfo="selectedLeadInfo"
            :leadTitle="leadTitle"
            v-on:loadLeadInfo="updateLeadInfo($event)"
          />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
  <!--  -->
  <script>
import {
  BLink,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BButton,
  BForm,
  BModal,
  BCol,
  BRow,
  BCardText,
  BFormTextarea,
  BSpinner,
  BAlert,
  VBTooltip,
  BFormDatepicker,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FinancialEstimationForm from "@/views/admin/finance/project/FinancialEstimationForm.vue";
import { required, max, size, ext, max_value, min_value } from "@validations";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { projectTypeConstants } from "@/helpers/constant/projectTypeConstant";
import { paymentTypeConstants } from "@/helpers/constant/paymentTypeConstant";
import { leadStatusTypeConstants } from "@/helpers/constant/leadStatusTypeConstant";
import {
  LEAD_ACTIVITY_ACCESS,
  LEAD_ACTIVITY_CREATE,
  LEAD_ACTIVITY_EDIT,
  LEAD_ACTIVITY_DELETE,
} from "@/helpers/permissionsConstant";
import UserCard from "@/layouts/components/UserCard.vue";

export default {
  name: "LeadDetailsView",
  components: {
    FinancialEstimationForm,
    BLink,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    UserCard,
    BPagination,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BRow,
    BCardText,
    BSpinner,
    BFormTextarea,
    flatPickr,
    BAlert,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      LEAD_ACTIVITY_ACCESS,
      LEAD_ACTIVITY_CREATE,
      LEAD_ACTIVITY_EDIT,
      LEAD_ACTIVITY_DELETE,
      projectTypeConstants,
      paymentTypeConstants,
      leadStatusTypeConstants,
      modalDescription: "",
      projectType: "",
      paymentType: "",
      amount: "",
      timeDuration: "",
      milestones: "",
      selectedLeadInfo: {},
      milestoneAmmount: [],
      milestoneDeadLine: [],
      milestonePaymentType: [],

      leadId: this.$route.params.id,
      leadTitle: "",
      leadActivityLogId: "",
      modelType: "",
      contactMedium: "",
      nextContactDate: "",
      selectContactMediumOption: [
        {
          value: "Face To Face Meeting",
          text: "Through a Face To Face Meeting",
        },
        { value: "Virtual Meeting", text: "Through a Virtual Meeting" },
        { value: "Email", text: "Through an Email" },
        { value: "Call", text: "Through a Phone Call" },
        { value: "Text", text: "Through a Text" },
        { value: "Proposal Sent", text: "Though Sending Proposal" },
      ],
      remarks: "",
      lastContactDate: "",
      nextContactDate: "",
      statusIdOption: [],
      leadStatusId: "",
      selectedLeadStatus: "",
      file: null,

      pageLength: 10,
      columns: [
        {
          label: "Contact Medium",
          field: "contact_through",
          sortable: false,
        },
        {
          label: "Remarks",
          field: "format_remarks",
          sortable: false,
        },
        {
          label: "File",
          field: "format_file",
          sortable: false,
        },
        {
          label: "Status",
          field: "format_lead_status",
          sortable: false,
        },

        {
          label: "Last Contact Date & Time",
          field: "last_contact_date",
          formatFn: this.formatFnTableLastContactDate,
          sortable: false,
        },
        {
          label: "Next Contact Date & Time",
          field: "next_contact_date",
          formatFn: this.formatFnTableNextContactDate,
          sortable: false,
        },
        {
          label: "Created By",
          field: "format_created_by",
          sortable: false,
        },
        {
          label: "Created On",
          field: "created_at",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isMileStoneLoading: false,
      isLeadActivityFormSubmitLoading: false,
      isFinancialEstimationFormLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
      lead: {},
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    this.loadLeadData();
  },
  methods: {
    checkForFinalStatus() {
      this.selectedLeadStatus = (this.statusIdOption || []).find((item) => {
        if (item?.type == 2 && item?.id == this.leadStatusId) {
          return item;
        } else if (item?.type == 3 && item?.id == this.leadStatusId) {
          return item;
        }
      });
    },
    showDescriptionModal(remarks) {
      if (remarks) {
        this.modalDescription = remarks;
      } else {
        this.modalDescription = "N/A";
      }

      this.$bvModal.show("description-modal");
    },
    hiddenDescriptionModal() {
      this.$bvModal.hide("description-modal");
    },
    async loadLeadData() {
      try {
        const id = this.$route.params.id;
        const resLead = await this.$api.get(
          `api/leads/${id}?include=user,leadCategory,leadSource,leadStatus,department,leadCountry,dialCodeCountry,team`
        );

        const dataLead = resLead?.data?.data;

        this.leadTitle = dataLead?.title;

        this.lead = dataLead;

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }

      this.loadAvailableStatus();
    },
    updateMilestonePaymentType() {
      this.milestonePaymentType = [];
      for (let i = 0; i < this.milestones; i++) {
        this.milestonePaymentType.push(this.paymentType);
      }
    },

    loadMileStone() {
      this.milestoneAmmount = [];
      this.milestonePaymentType = [];
      for (let i = 0; i < this.milestones; i++) {
        this.milestoneAmmount.push(this.amount / this.milestones);
        this.milestonePaymentType.push(this.paymentType);
      }
      this.isMileStoneLoading = false;
      return true;
    },

    updateMilestoneInfo() {
      this.isMileStoneLoading = true;
      setTimeout(this.loadMileStone, 300);
    },
    async loadAllStatus() {
      //status
      const status = await this.$api.get("api/lead-status/active-all");
      this.statusIdOption = (status?.data?.data || []).map((item) => {
        let position = "";

        if (item?.type == 2) {
          position = "(Success)";
        } else if (item?.type == 3) {
          position = "(Dead)";
        }
        let name = `${item?.name} ${position}`;
        return {
          name,
          id: item.id,
        };
      });
    },
    async loadAvailableStatus() {
      try {
        const [status] = await Promise.all([this.getLeadStatus()]);

        const existingStatusId = this.lead?.leadStatus?.data?.sequence;

        let availableStatus = [];
        availableStatus = (status?.data?.data || []).filter((item) => {
          if (item?.sequence >= existingStatusId) {
            let name = item.name;
            return {
              name,
              id: item.id,
            };
          }
        });
        this.statusIdOption = availableStatus;
        this.statusIdOption = (availableStatus || []).map((item) => {
          let position = "";

          if (item?.type == 2) {
            position = "(Success)";
          } else if (item?.type == 3) {
            position = "(Dead)";
          }
          let name = `${item?.name} ${position}`;
          return {
            name,
            type: item?.type,
            id: item.id,
          };
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    formatFnTableLastContactDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },
    formatFnTableNextContactDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "N/A";
    },

    onSearchUsers(search, loading) {
      if (search.length) {
        const id = this.$route.params.id;
        loading(true);
        if (this.delayTimer) {
          clearTimeout(this.delayTimer);
          this.delayTimer = null;
        }

        this.delayTimer = setTimeout(async () => {
          const resUsers = await this.$api.get(
            `api/department/${id}/users/search?include=department`,
            {
              params: {
                q: search,
              },
            }
          );

          const dataUsers = resUsers?.data?.data;
          this.usersOption = (dataUsers || []).map((item) => {
            let name = item.name;
            let department = null;

            if (item.department_id) {
              name = `${item.name} (${item?.department?.data?.name})`;
              department = item?.department?.data?.name;
            }

            return {
              name: name,
              id: item.id,
              department,
            };
          });
          loading(false);
        }, 800);
      }
    },
    async onShow(value) {
      this.modelType = "editModel";
      this.leadActivityLogId = value?.id;
      this.contactMedium = value?.contact_through;
      this.remarks = value?.remarks;
      this.nextContactDate = value?.next_contact_date;
      this.leadStatusId = this.lead?.lead_status_id;
      this.showModal();
    },
    showModal() {
      this.leadStatusId = this.lead?.leadStatus?.data?.id;
      this.$bvModal.show("modal-activity-log-show-form");
      this.loadAvailableStatus();
    },

    showEstimationModal() {
      this.selectedLeadInfo = {
        leadId: this.lead?.id,
        departmentId: this.lead?.department_id,
      };

      this.$bvModal.show("modal-financial-estimation-show-form-on-lead");
    },

    hideEstimationModal() {
      this.$bvModal.hide("modal-financial-estimation-show-form");
      this.resetEstimationModal();
    },
    resetEstimationModal() {
      this.projectType = "";
      this.amount = "";
      this.timeDuration = "";
      this.milestones = "";
      this.paymentType = "";
      this.milestoneAmmount = [];
      this.milestoneDeadLine = [];
      this.milestonePaymentType = [];
    },
    hiddenModal() {
      this.$bvModal.hide("modal-activity-log-show-form");
      this.resetModal();
    },
    resetModal() {
      this.leadId = "";
      this.leadActivityLogId = "";
      this.contactMedium = "";
      this.file = null;
      this.remarks = "";
      this.nextContactDate = "";
      this.leadStatusId = "";
      this.selectedLeadStatus = "";
    },

    async getLeadAcitvityLog(params) {
      this.leadId = this.$route.params.id;
      return await this.$api.get(
        `api/leads/${this.leadId}/lead-activity-log?include=leadStatus`,
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
          },
        }
      );
    },
    updateLeadInfo: function () {
      this.loadLeadData();
      this.showFinancialEstimationComponent = false;
    },
    async getLeadStatus() {
      return await this.$api.get("api/lead-status/active-all");
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/lead-activity-log/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async loadItems() {
      try {
        const [leadAcitvityLog] = await Promise.all([
          this.getLeadAcitvityLog({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ]);

        const data = leadAcitvityLog?.data?.data;

        const meta = leadAcitvityLog?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    validationForm: async function () {
      this.$refs.leadActvityLogValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.leadActvityLogValidation.reset();
            if (this.modelType == "editModel") {
              this.isLeadActivityFormSubmitLoading = true;
              this.leadId = this.$route.params.id;
              const formData = new FormData();

              formData.append("_method", "PUT");

              if (this.leadId) {
                formData.append("lead_id", this.leadId);
              }

              if (this.contactMedium) {
                formData.append("contact_through", this.contactMedium);
              }
              if (this.remarks) {
                formData.append("remarks", this.remarks);
              }
              if (this.leadStatusId) {
                formData.append("lead_status_id", this.leadStatusId);
              }
              if (this.selectedLeadStatus) {
                formData.append(
                  "lead_status_type",
                  this.selectedLeadStatus?.type
                );
              } else {
                formData.append(
                  "lead_status_type",
                  this.leadStatusTypeConstants[0]?.value
                );
              }
              if (this.file) {
                formData.append("lead_activity_log_file", this.file);
              }

              formData.append("next_contact_date", this.nextContactDate);
              await this.$api.post(
                `/api/lead-activity-log/${this.leadActivityLogId}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );

              this.isLeadActivityFormSubmitLoading = false;
              this.hiddenModal();
              this.loadItems();
              this.loadLeadData();
            } else {
              this.isLeadActivityFormSubmitLoading = true;
              this.leadId = this.$route.params.id;
              const formData = new FormData();
              if (this.leadId) {
                formData.append("lead_id", this.leadId);
              }

              if (this.contactMedium) {
                formData.append("contact_through", this.contactMedium);
              }
              if (this.remarks) {
                formData.append("remarks", this.remarks);
              }
              if (this.leadStatusId) {
                formData.append("lead_status_id", this.leadStatusId);
              }
              if (this.selectedLeadStatus) {
                formData.append(
                  "lead_status_type",
                  this.selectedLeadStatus?.type
                );
              } else {
                formData.append(
                  "lead_status_type",
                  this.leadStatusTypeConstants[0]?.value
                );
              }
              if (this.file) {
                formData.append("lead_activity_log_file", this.file);
              }

              formData.append("next_contact_date", this.nextContactDate);

              await this.$api.post("/api/lead-activity-log", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
              this.isLeadActivityFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();
              try {
                const id = this.$route.params.id;
                const resLead = await this.$api.get(
                  `api/leads/${id}?include=user,leadCategory,leadSource,leadStatus,department,leadCountry,dialCodeCountry`
                );

                const dataLead = resLead?.data?.data;

                this.lead = dataLead;
              } catch (error) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }

              this.loadAvailableStatus();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Lead Activity Log Created Successfully ",
                },
              });

              try {
                const id = this.$route.params.id;
                const resLead = await this.$api.get(
                  `api/leads/${id}?include=user,leadCategory,leadSource,leadStatus,department,leadCountry,dialCodeCountry`
                );

                const dataLead = resLead?.data?.data;
                this.lead = dataLead;
              } catch (error) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }
            }
          } catch (error) {
            this.isLeadActivityFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.leadActvityLogValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

  <style lang="scss">
.custom-font {
  font-size: 11px !important;
}
.lead-details-table {
  // font-size: 13px !important;
  // font-weight: 600 !important;
  min-height: 140px !important;
  white-space: nowrap !important;
  font-size: 13px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}

.required-label label::after {
  content: " *";
  color: red;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
